import CGIcon from '../socials/coingecko';
import GithubIcon from '../socials/github';
import TelegramIcon from '../socials/telegram';
import TwitterIcon from '../socials/twitter';

export default function Footer() {
   return (
      <footer className="mx-4 lg:container lg:mx-auto pt-20 pb-8 relative flex flex-col lg:flex-row justify-between items-center space-y-4 lg:space-y-0 lg:space-x-4">
         <ul className="flex items-center space-x-4">
            <li className="text-white border-b-2 border-transparent hover:border-white">
               <a href="https://defiplaza.net/blog" target="_blank">
                  Blog
               </a>
            </li>
            <li className="text-white border-b-2 border-transparent hover:border-white">
               <a href="https://docs.defiplaza.net" target="_blank">
                  Docs
               </a>
            </li>
            <li className="text-white border-b-2 border-transparent hover:border-white">
               <a href="https://stats.defiplaza.net/pools/radixplaza" target="_blank">
                  Analytics
               </a>
            </li>
         </ul>
         <div className="flex items-center space-x-4">
            <p className="text-white hover:text-primary-100">
               <a href="https://t.me/defiplaza" target="_blank">
                  <TelegramIcon />
               </a>
            </p>
            <p className="text-white hover:text-primary-100">
               <a href="https://twitter.com/defiplaza" target="_blank">
                  <TwitterIcon />
               </a>
            </p>
            <p className="text-white hover:text-primary-100">
               <a href="https://github.com/OMegaSyndicate" target="_blank">
                  <GithubIcon />
               </a>
            </p>
            <p className="text-white hover:text-primary-100">
               <a href="https://www.coingecko.com/en/coins/defiplaza" target="_blank">
                  <CGIcon />
               </a>
            </p>
            <p className="text-white hover:text-primary-100">
               <a href="https://coincodex.com/exchange/defiplaza/" target="_blank">
                  <img src="/assets/img/listings/coincodex-icon.png" className="size-7" />
               </a>
            </p>
         </div>
      </footer>
   );
}
